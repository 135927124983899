<template>
    <section class="h-100">
        <div class="row mx-0 h-100">
            <div class="col-xl col-lg col-md col-sm col px-0 h-100 overflow-auto custom-scroll border-right">
                <div class="row mx-0 align-items-center border-bottom px-2" style="height:56px;">
                    <img class="border bg-whitesmoke rounded-circle" width="48" height="48" :src="delivery.delivery.foto" alt="" />
                    <div class="col f-15 text-general">
                        <div class="row mx-0 align-items-center">
                            <p class="tres-puntos col-6 px-1 text-general f-15">{{ delivery.delivery.nombre }}</p>
                            <p class="tres-puntos ml-auto text-general">{{ formatearFecha(delivery.hora_ult_chat,'D MMM Y - HH:mmA') }}</p>
                        </div>
                        <div class="row mx-0">
                            <p class="tres-puntos col-6 px-1 text-general2 f-14">ID {{ delivery.id }}</p>
                            <p class="tres-puntos text-right text-general col-6 px-1 f-14"> {{ formatearFecha(delivery.fecha_entrega,'D MMM Y - HH:mm a') }}</p>
                        </div>
                    </div>
                </div>
                <div class="height-chat-padre">
                    <chat :tipo="7" :user="id_pedido" :momento="1" @actualizar="actualizarPedidos" />
                </div>
            </div>
            <div class="col-xl-5 col-lg-5 col-md col-sm col px-0 h-100">
                <div class="d-flex f-14 mx-0 px-2 border-bottom" style="height:56px;">
                    <div
                    v-for="tab in tabs" :key="tab.id"
                    class="col px-0 h-100 d-middle-center text-center cr-pointer"
                    :class="activa==tab.components?'border-b-black text-general':''"
                    @click="activa=tab.components"
                    >
                        {{ tab.nombre }}
                    </div>
                </div>
                <div class="height-components overflow-auto custom-scroll" style="height:calc(100vh - 275px);">
                    <component :is="activa" :finalizado="true" />
                    <!-- <detalle v-show="activa == 'Detalle'" />
                    <productos v-show="activa == 'Productos'" />
                    <chat-leechero v-show="activa == 'Chat2'" :finalizado="true" />
                    <chat-cliente v-show="activa == 'Reclamo'" :finalizado="true" /> -->
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import {mapGetters} from 'vuex'

export default {
    components: {
        //Detalle: () => import('./components/detalleLeechero'),
        Detalle: () => import('~/pages/pedidos/components/detalleAdminPedido.vue'),
        Productos: () => import('./components/productosLeechero'),
    },
    props:{
        delivery: {
            type: Object,
            default(){
                return {
                    id: null,
                    hora_ult_chat: null,
                    fecha_entrega: null,
                    delivery: {
                        nombre: '',
                        foto: '',
                    }
                }
            }
        },
    },
    data(){
        return {
            activa: 'Detalle',
            tabs: [
                { id: 1, nombre: 'Detalle', components: 'Detalle'},
                { id: 2, nombre: 'Productos', components: 'Productos'},
            ],
            chat: 5
        }
    },
    computed:{
        ...mapGetters({
            id_pedido: 'pedidos/pedidos_admin/id_pedido',
            cliente: 'pedidos/pedidos_admin/cliente',
            detalle: 'pedidos/pedidos_admin/pedido'
        }),
    },
    destroyed(){
        this.$store.commit('pedidos/pedidos_admin/set_id_pedido', null)
        this.$store.commit('pedidos/pedidos_admin/set_pedido', {})
        this.$store.commit('pedidos/pedidos_admin/set_productos', [])
    },
    methods: {
        async actualizarPedidos(){
            try {
                this.$emit('actualizar')
            } catch (e){
                this.error_catch(e)
            }
        },
    }
}
</script>

<style lang="scss" scoped>
.bg-rosa{
    background: #f5f5f5;
}
.text-orange{
    color: #FF612B;
}
.text-morado{
    color: #9508EB;
}
.alto{
    height: calc(100vh - 64px);
}
.height-chat-padre{
    height:calc(100vh - 259px) !important;
}
.height-components{
    height:calc(100vh - 217px) !important;
}

@media (max-width:1000px){
    .height-chat-padre{
        height:calc(100vh - 274px) !important;
    }
    .height-components{
        height:calc(100vh - 275px) !important;
    }
}
</style>
